import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/shared/Container"
import Work from "../components/Work"
import WorkList from "../components/WorkList"
import { graphql } from "gatsby"

const WorkTemplate = ({ data }) => {
  const work = data.contentfulWorks
  const otherWorks = data.allContentfulWorks.edges

  return (
    <Layout breadCrumb={{ to: "/", text: `Works` }}>
      <SEO title={work.title} />
      <Container>
        <Work work={work} />
        <Headline>
          <h3>other works</h3>
        </Headline>
        <WorkList works={otherWorks} />
      </Container>
    </Layout>
  )
}
const Headline = styled.div`
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-top: 40px;
  margin-bottom: 32px;
  display: block;
  text-align: center;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
    background-color: ${props => props.theme.colors.baseFontColor};
    z-index: -1;
    opacity: 0.3;
  }

  h3 {
    display: inline-block;
    background-color: white;
    z-index: 3;
    padding-left: 32px;
    padding-right: 32px;
  }
`

export const query = graphql`
  query($slug: String!) {
    allContentfulWorks(
      sort: { fields: year, order: DESC }
      filter: { slug: { ne: $slug } }
    ) {
      edges {
        node {
          title
          slug
          year(formatString: "YYYY")
          roles
          color
          keyImage {
            resize(width: 1200) {
              src
            }
          }
        }
      }
    }
    contentfulWorks(slug: { eq: $slug }) {
      title
      roles
      client
      year(formatString: "YYYY")
      color
      note {
        note
      }
      site
      keyImage {
        resize(width: 1600) {
          src
        }
      }
      images {
        resize(width: 1600) {
          src
          aspectRatio
        }
      }
      video {
        file {
          url
        }
      }
    }
  }
`

export default WorkTemplate
