import React from "react"
import styled from "@emotion/styled"
import { device } from "../../utils/device"

const Note = ({text, keyColor}) => {
  return (
    <NoteBlock color={keyColor}>
      <Text>
        {text}
      </Text>
    </NoteBlock>
  )
}

const NoteBlock = styled.div`
  padding: 32px;
  margin: 32px 0;
  width: 65%;
  border-left: 2px solid ${props => props.color || props.theme.colors.baseFontColor };

  @media ${device.mobile} {
    width: 100%;
  }
`

const Text = styled.p`
  font-size: 14px;
  line-height: 1.8;
`

export default Note