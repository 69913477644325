import React from "react"
import styled from "@emotion/styled"
import { device } from "../../utils/device"

const InfoBlock = ({label, text, to}) => {
  const linkOrText = () => {
    if (to) {
      return (
        <a
          href={to}
          target="_blank"
          rel="noreferrer noopener"
        >
          { text }
        </a>
      )
    }

    return (
      <p>{ text }</p>
    )
  }

  return (
    <StyledInfoBlock>
      <span>{label}</span>
      { linkOrText() }
    </StyledInfoBlock>
  )
}

const StyledInfoBlock = styled.div`
  margin-top: 32px;
  span {
    display: block;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 0.2em;
    margin-bottom: 8px;

    @media ${device.mobile} {
      color: ${props => props.theme.colors.baseGrayColor};
      font-size: 0.7em;
    }
  }

  a {
    color: inherit;
    &:hover {
      color: ${props => props.theme.colors.baseGrayColor};
    }
  }
`

export default InfoBlock;