import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import MyIcon from "../MyIcon"
import InfoBlock from "./InfoBlock"
import ImageList from "./ImageList"
import Note from "./Note"
import { device } from "../../utils/device"

const Work = ({ work }) => {
  return (
    <Wrapper>
      <Credit>
        <IconWrapper>
          <MyIcon keyColor={work.color} />
        </IconWrapper>
        <Title color={work.color}>{work.title}</Title>
        <InfoBlock label="role" text={work.roles.join()} />
        <InfoBlock label="client" text={work.client} />
        <InfoBlock label="year" text={work.year} />
        {work.site ? (
          <InfoBlock label="URL" text={work.site} to={work.site} />
        ) : null}
      </Credit>
      <WorkMain>
        {work.video ? (
          <Video src={work.video.file.url} poster={work.images[0]} />
        ) : (
          <WorkMainImageContent image={work.keyImage.resize.src} />
        )}

        <MobileCredit>
          <h1> {work.title}</h1>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              font-size: 12px;
              > div {
                margin-right: 16px;
              }
            `}
          >
            <InfoBlock label="role" text={work.roles.join()} />
            <InfoBlock label="client" text={work.client} />
            <InfoBlock label="year" text={work.year} />
            {work.site ? (
              <InfoBlock label="URL" text={work.site} to={work.site} />
            ) : null}
          </div>
        </MobileCredit>
        {work.note ? (
          <Note text={work.note.note} keyColor={work.color} />
        ) : null}
        <WorkImages>
          <ImageList client={work.client} images={work.images} />
        </WorkImages>
      </WorkMain>
    </Wrapper>
  )
}

const Credit = styled.aside`
  font-size: 14px;

  @media ${device.mobile} {
    display: none;
  }
`

const Video = props => {
  const src = props.src
  const poster = props.poster.resize.src || null
  return (
    <video
      controls
      src={src}
      poster={poster}
      css={css`
        width: 100% !important;
      `}
    ></video>
  )
}

const MobileCredit = styled.div`
  display: none;

  @media ${device.mobile} {
    display: block;
    padding-top: 32px;
    padding-bottom: 32px;
  }
`
const WorkMain = styled.main`
  width: 75%;
  @media ${device.mobile} {
    width: 100%;
  }
`
const WorkMainImageContent = styled.div`
  width: 100%;
  overflow: hidden;
  min-height: calc(100vh - 192px);
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;

  @media ${device.mobile} {
    min-height: 280px;
  }
`
const WorkImages = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const IconWrapper = styled.div`
  display: block;
  width: 128px;
  margin-bottom: 48px;
  transform: translateX(-4px);
`

const Title = styled.h1`
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-block;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -4px;
    background-color: ${props => props.color || "black"};
  }
`

export default Work
