import React from "react"
import styled from "@emotion/styled"

const ImageList = ({client, images}) => {
  if (!images) return null
  const isBorder = client === `bibito` ? true : false

  return (
    images.map(image => (
      <Image
        src={image.resize.src}
        aspectRatio={image.resize.aspectRatio}
        isBorder={isBorder}
      />
    ))
  )
}

const Image = styled.img`
  display: block;
  width: ${props => props.aspectRatio < 1.0 ? `48` : `100`}%;
  margin-bottom: 32px;
  border: 1px solid ${props => props.isBorder ? `#A6A6A6` : 'transparent'};
`

export default ImageList